import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    message: string;
    title: string;
  }

import { Component,  Inject } from '@angular/core';
  @Component({
    selector: 'modal-error',
    templateUrl: 'modalError.html',
    styleUrls: ['./modalError.css']
  })

  export class ModalError {
  
    constructor(
      public dialogRef: MatDialogRef<ModalError>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  } 
    