import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

const BASE_URL =
  "https://mc684iqco2.execute-api.us-east-1.amazonaws.com/development/v1";

@Injectable({
  providedIn: "root",
})
export class ForgotPassEmailService {
  constructor(private http: HttpClient) {}

  sendRecoveryEmail(email: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const body = {};

    return this.http.post(
      `${BASE_URL}/users/forgot-password?email=${email}`,
      body,
      { headers }
    );
  }

  validateToken(token: string) {
    const url = `${BASE_URL}/users/forgot-password-validate`;
    const body = {};

    return fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "x-recovery-token": token,
      },
    });
  }

  resetPassword(newPass: string, token: string) {
    const headers = new HttpHeaders({
      "x-recovery-token": token,
    });

    const body = {
      password: newPass,
    };

    return this.http.put(`${BASE_URL}/users/forgot-password-reset`, body, {
      headers,
    });
  }

}
