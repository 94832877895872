import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Component,  Inject } from '@angular/core';


  @Component({
    selector: 'reset-password-modal',
    templateUrl: 'resetPasswordModal.component.html',
    styleUrls: ['./resetPasswordModal.component.css']
  })


  export class ResetPasswordModal {


    email = '';

    constructor(
      public dialogRef: MatDialogRef<ResetPasswordModal>,
      @Inject(MAT_DIALOG_DATA) public data: {}) {}

  
    onNoClick(e): void {
      this.dialogRef.close(`${this.email}|cancel`);
    }

    onClick(){
      this.dialogRef.close(`${this.email}|send`);
    }
  
  }