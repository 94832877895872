import { Component, OnInit, Inject } from '@angular/core';
import { SincronizacionEnvios } from 'src/app/_services/sincronizar.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mis-envios',
  templateUrl: './mis-envios.component.html',
  styleUrls: ['./mis-envios.component.css']
})
export class MisEnviosComponent implements OnInit {

  constructor(private sincronizador: SincronizacionEnvios, public dialog: MatDialog, private route: ActivatedRoute) { }

  pedidos: any[] = [];
  ngOnInit() {
    this.compraExitosa();
    let json = {
      jwt: JSON.parse(localStorage.getItem('currentUser')).token
    };
    this.sincronizador.obtenerPedidos(json)
    .subscribe((data: any) => {
      if(data.code == 200){
        this.pedidos = data.envios;
        this.pedidos.reverse();
      }
    });
  }
  verDetalle(index){
    this.dialog.open(DetalleEnvio, {
      data: {info: this.pedidos[index]}
    });
  }

  compraExitosa(){
    let info = this.route.snapshot.paramMap.get('success');
    if(info){
      this.dialog.open(EnvioExitoso);
    }
  }

}

@Component({
  selector: 'detalle-envio',
  templateUrl: './../mensajes/mensajeDetalleEnvio.html',
  styleUrls: ['./../mensajes/mensajeDetalleEnvio.css']
})

export class DetalleEnvio{
  informacionEnvio :any = {};
  constructor(
    public dialogRef: MatDialogRef<DetalleEnvio>
    ,@Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(this.data.info);
      this.informacionEnvio = this.data.info;
  }

  cerrar(){
    this.dialogRef.close();
  }
}

@Component({
  selector: 'envio-exitoso',
  templateUrl: './../mensajes/mensajeExitoEnvio.html',
  styleUrls: ['./../mensajes/mensajeExitoEnvio.css']
})

export class EnvioExitoso{
  informacionEnvio :any = {};
  constructor( public dialogRef: MatDialogRef<EnvioExitoso> ) { }

  aceptarMensaje(){
    this.dialogRef.close();
  }
}