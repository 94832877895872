import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-conectarapi',
  templateUrl: './conectarapi.component.html',
  styleUrls: ['./conectarapi.component.css']
})
export class ConectarapiComponent implements OnInit {

  private routeSub: Subscription;
  constructor(private route: ActivatedRoute, private location: Location) {}

  tipoIntegracion: number = 0;
  urlTienda: string;
  sicronizar() {
    window.location.href = environment.enjoyServices.shopifyLink + this.urlTienda;
  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.tipoIntegracion = params.id;
      if(this.tipoIntegracion == 0 || this.tipoIntegracion == undefined){
        window.location.href = '/';
      }
    });
  }

}
