import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    message: string;
  }

import { Component,  Inject } from '@angular/core';
  @Component({
    selector: 'modal-success',
    templateUrl: 'modalSuccess.html',
    styleUrls: ['./modalSuccess.css']
  })

  export class ModalSuccess {
  
    constructor(
      public dialogRef: MatDialogRef<ModalSuccess>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  } 
    