import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmDirectionModule } from 'agm-direction';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent, ErrorInicio } from './login';
import { ResetPasswordComponent } from './resetPassword';
import { RegisterComponent, CreadaFail, CreadaExito } from './register/register/register.component';
import { EnviosComponent, CrearEnvio, SeleccionarOrigen , ElegirFechaPedido, CreditosNoDisponibles} from './components/envios/envios.component';
import { IntegracionesComponent } from './components/integraciones/integraciones.component';
import { MisEnviosComponent , DetalleEnvio, EnvioExitoso} from './components/mis-envios/mis-envios.component';
import { ConfiguracionComponent, CambiarContrasena } from './components/configuracion/configuracion.component';
import { CreditosComponent, MuestraMensaje } from './components/creditos/creditos.component';
import { MatButtonModule } from '@angular/material/button';
import { ConectarapiComponent } from './components/conectarapi/conectarapi.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { IexitosaComponent } from './components/iexitosa/iexitosa.component';
import { PagoComponent } from './components/pago/pago.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ExpressComponent } from './components/express/express.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { environment } from 'src/environments/environment';
import { ResetPasswordModal } from './login/mensajes/resetPasswordModal/resetPasswordModal.component';
import { ModalError } from './components/mensajes/modalError/modalError';
import { ModalSuccess } from './components/mensajes/modalSuccess/modalSuccess';
import { ForgotPassEmailService } from './_services/forgot-pass-email.service';
@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        routing,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatCheckboxModule,
        FormsModule,
        MatListModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatGridListModule,
        MatTableModule,
        MatSliderModule,
        MatDialogModule,
        MatSelectModule,
        AgmCoreModule.forRoot({
            apiKey: environment.enjoyKeys.googleMapsAPI,
            libraries: ['places']
        }),
        MatGoogleMapsAutocompleteModule.forRoot(),
        AgmDirectionModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        HttpClientJsonpModule
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        ResetPasswordComponent,
        RegisterComponent,
        EnviosComponent,
        IntegracionesComponent,
        MisEnviosComponent,
        ConfiguracionComponent,
        CreditosComponent,
        ConectarapiComponent,
        IexitosaComponent,
        PagoComponent,
        MuestraMensaje,
        CrearEnvio,
        CambiarContrasena,
        SeleccionarOrigen,
        ExpressComponent,
        DetalleEnvio,
        ElegirFechaPedido,
        CreditosNoDisponibles,
        CreadaFail,
        CreadaExito,
        ErrorInicio,
        ResetPasswordModal,
        ModalError,
        ModalSuccess,
        EnvioExitoso
    ],
    entryComponents: [MuestraMensaje, 
        CrearEnvio, 
        CambiarContrasena, 
        SeleccionarOrigen, 
        DetalleEnvio, 
        ElegirFechaPedido,
        CreditosNoDisponibles,
        CreadaFail, 
        CreadaExito,
        ErrorInicio,
        ResetPasswordModal,
        ModalError,
        ModalSuccess,
        EnvioExitoso
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
        ForgotPassEmailService
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }