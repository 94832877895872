import { Component, OnInit } from "@angular/core";
import { SincronizacionEnvios } from "src/app/_services/sincronizar.service";
import { Router } from "@angular/router";
import styleMap from "src/app/components/express/stylemap";
import { CreditosNoDisponibles } from "../envios/envios.component";
import { UserService } from "src/app/_services";
import { MatDialog } from "@angular/material/dialog";
import { NgForm } from "@angular/forms";

import { ModalError } from "../../components/mensajes/modalError/modalError";

@Component({
  selector: "app-express",
  templateUrl: "./express.component.html",
  styleUrls: ["./express.component.css"],
})
export class ExpressComponent implements OnInit {
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0;
  };

  envioForm: NgForm;
  validForm: boolean = false;
  necessaryCredits: boolean = false;
  styles = styleMap;
  latMexico = 19.4326077;
  miniLoading: boolean = false;
  originMap = "";
  destinationMap = "";
  lngMexico = -99.13320799999997;
  travelMode = "DRIVING";
  loadingPage = false;
  zoom = 12;
  distancia: any = 0;
  drivingOptions: any = {
    departureTime: new Date(Date.now()),
    trafficModel: "optimistic",
  };
  precio: any = 0;
  minDate: any = new Date();
  renderOptions = {
    suppressMarkers: true,
    polylineOptions: {
      strokeColor: "#5E8CB5",
      strokeWeight: 5,
    },
  };
  markers: any[] = [];
  puntosEntrega = [
    {
      direccion: " ",
      interior: " ",
      contenido: " ",
      nombre: " ",
      correo: " ",
      telefono: " ",
      referencias: " ",
    },
  ];
  puntoFinal = " ";
  puntoRecogida = {
    direccion: " ",
    interior: " ",
    contenido: " ",
    nombre: " ",
    telefono: " ",
    fecha: " ",
    hora: " ",
    referencias: " ",
  };
  waypoints: any = [];

  creditos: any = 0.1;

  constructor(
    public enviosService: SincronizacionEnvios,
    private router: Router,
    public userService: UserService,
    public dialog: MatDialog
  ) {
    this.userService.creditos$.subscribe((creditos) => {
      this.creditos = creditos;

      if (this.creditos <= 0) {
        this.dialog.open(CreditosNoDisponibles);
      }
    });
  }

  ngOnInit() {}

  openDialogError() {
    this.dialog.open(ModalError, {
      data: {
           message:"No es posible realizar la operación en este momento",
           title:"Lo sentimos ocurrió un problema"
         }

    });
  }

  agregarPuntoInputs() {
    this.puntosEntrega.push({
      direccion: "",
      interior: "",
      contenido: "",
      nombre: "",
      correo: "",
      telefono: "",
      referencias: "",
    });
    this.solicitaDireccion();
  }

  solicitaDireccion() {
    this.distancia = 0;
    let noDireccion: boolean = true;
    let ultimos = {
      markers: this.markers,
      waypoints: this.waypoints,
      originMap: this.originMap,
      destinationMap: this.destinationMap,
    };
    this.markers = [];
    this.waypoints = [];
    this.originMap = "";
    this.destinationMap = "";
    if (this.puntosEntrega.length > 1) {
      this.puntosEntrega.forEach((item: any) => {
        noDireccion = item.direccion == "" ? false : noDireccion;
      });
      if (noDireccion && this.puntoRecogida.direccion != "") {
        let url: string = "";
        url =
          url +
          this.puntoRecogida.direccion +
          "&destination=Zócalo, Centro, Ciudad de México, CDMX&waypoints=optimize:true|";
        this.puntosEntrega.forEach((item: any, index: number) => {
          if (index != this.puntosEntrega.length - 1) {
            url = url + item.direccion + "|";
          } else {
            url = url + item.direccion;
            this.enviosService.obtenerDireccionCorta(url).subscribe(
              (res: any) => {
                if (res.status == "OK" && res.routes.length != 0) {
                  this.miniLoading = false;
                  res.routes[0].waypoint_order.forEach((item, index) => {
                    if (index != res.routes[0].waypoint_order.length - 1) {
                      this.waypoints.push({
                        location: this.puntosEntrega[item].direccion,
                        stopover: true,
                      });
                    } else {
                      this.originMap = this.puntoRecogida.direccion;
                      this.destinationMap = this.puntosEntrega[item].direccion;
                    }
                  });
                } else if (res.status == "ZERO_RESULTS") {
                  this.miniLoading = false;
                }
              },
              (err) => {
                console.log(err);
              }
            );
          }
        });
      } else {
        this.markers = ultimos.markers;
        this.waypoints = ultimos.waypoints;
        this.originMap = ultimos.originMap;
        this.destinationMap = ultimos.destinationMap;
        this.miniLoading = false;
      }
    } else {
      if (
        this.puntoRecogida.direccion != "" &&
        this.puntosEntrega[0].direccion != ""
      ) {
        this.originMap = this.puntoRecogida.direccion;
        this.destinationMap = this.puntosEntrega[0].direccion;
        this.miniLoading = false;
      } else {
        this.miniLoading = false;
      }
    }
  }

  quitarPuntoInputs(index) {
    this.puntosEntrega.splice(index, 1);
    this.solicitaDireccion();
  }

  cambioDeRuta(event: any) {
    if (event) {
      this.loadingPage = true;
      if (event.routes) {
        if (event.routes.length > 0) {
          this.distancia = 0;
          let distanciaSuma: any = 0;
          let markerStart = {
            lat: event.routes[0].legs[0].start_location.lat(),
            lng: event.routes[0].legs[0].start_location.lng(),
            iconUrl: "assets/images/icon/marker1.png",
            direccion: event.routes[0].legs[0].start_address,
          };
          let ordenEnvios = [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            ,
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
          ];
          this.markers.push(markerStart);
          event.routes[0].legs.forEach((value, indexV) => {
            distanciaSuma = distanciaSuma + value.distance.value / 1000;
            let marker = {
              lat: value.end_location.lat(),
              lng: value.end_location.lng(),
              iconUrl: "assets/images/icon/marker2.png",
              direccion: value.end_address,
              label: {
                color: "#4F4F4F",
                text: ordenEnvios[indexV],
                fontSize: "24px",
                fontWeight: "bold",
                fontFamily: "Oswald",
                paddingTop: "10px",
              },
            };
            this.markers.push(marker);
          });

          this.distancia = distanciaSuma;
          const minKm = 3;
          const tarifa = 28;
          const tarifaPerKm = 8;

          if (this.distancia <= minKm) {
            this.precio = tarifa;
          } else {
            let calculo = (Math.ceil(this.distancia) - minKm) * tarifaPerKm;
            this.precio = calculo + tarifa;
          }
        } else {
          this.distancia = 0;
        }
      } else {
        this.distancia = 0;
      }
      this.loadingPage = false;
    }

    const credits = parseInt(this.creditos);
    this.necessaryCredits = credits > parseInt(this.precio);
  }

  validateForm() {
    if (this.validPuntoRecogida() && this.validPuntosEntrega()) {
      this.validForm = true;
    } else {
      this.validForm = false;
    }
  }

  validPuntosEntrega() {
    const invalidArray = [];

    this.puntosEntrega.map((punto) => {
      let {
        interior: interiorEntrega,
        contenido: contenidoEntrega,
        nombre: nombreEntrega,
        correo: correoEntrega,
        direccion: direccionEntrega,
        telefono: telefonoEntrega,
        referencias: referenciasEntrega,
      } = punto;

      telefonoEntrega = telefonoEntrega + "";

      if (
        this.isOnlyString(contenidoEntrega, nombreEntrega) &&
        this.isValidEmail(correoEntrega) &&
        this.isValidInteriorNumber(interiorEntrega) &&
        this.isValidDirection(direccionEntrega) &&
        telefonoEntrega.replace(/ /g, "").length > 0
      ) {
        invalidArray.push(true);
      } else {
        invalidArray.push(false);
      }
    });

    const filterInvalid = invalidArray.filter((item) => item === false);

    return filterInvalid.length === 0;
  }

  validPuntoRecogida() {
    let {
      interior: interiorRecogida,
      contenido: contenidoRecogida,
      nombre: nombreRecogida,
      direccion: direccionRecogida,
      telefono: telefonoRecogida,
      referencias: referenciasRecogida,
    } = this.puntoRecogida;

    telefonoRecogida = telefonoRecogida + "";

    if (
      this.isOnlyString(contenidoRecogida, nombreRecogida) &&
      this.isValidInteriorNumber(interiorRecogida) &&
      this.isValidDirection(direccionRecogida) &&
      telefonoRecogida.replace(/ /g, "").length > 0 &&
      referenciasRecogida.replace(/ /g, "").length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  isOnlyString(...array) {
    const regex = new RegExp(/^[a-zA-Z]*$/);

    const emptys = array.filter((str) => str.replace(/ /g, "").length === 0);
    if (emptys.length > 0) return false;

    const result = array.filter(
      (str) => regex.test(str.replace(/ /g, "")) === false
    );
    return result.length === 0;
  }

  isValidEmail(email) {

    if(email.length === 0){
      return true;
    }
    const regex = new RegExp(/^\S+@\S+\.\S+$/);
    return regex.test(email);
  }

  isValidInteriorNumber(txt: string) {
    const regex = new RegExp(/^[0-9a-zA-Z]*$/);
    const number = txt.replace(/ /g, "");
    return regex.test(number) && number.length != 0;
  }

  isValidDirection(direction) {
    return direction.indexOf("#") === -1 &&
      direction.replace(/ /g, "").length > 0
      ? true
      : false;
  }

  pagarEnvio() {
    // CREA JSON PARA PAGO
    let envio: any = {};
    envio.envio = {};
    envio.envio.nombrePedido = "EXPRESS";
    envio.envio.puntoRecogida = this.puntoRecogida;
    envio.envio.puntosEntrega = this.puntosEntrega;
    envio.jwt = JSON.parse(localStorage.getItem("currentUser")).token;
    //console.log('El JSON de entrada es: ', envio);
    this.loadingPage = true;
    this.enviosService.realizarPagoPorEnvio(envio).subscribe((data: any) => {
      this.loadingPage = false;

      if (data.code == 200) {
        this.router.navigate(["/misenvios/success"]);
      } else if (data.code >= 400) {
        this.openDialogError();
      }
    });
  }
}
