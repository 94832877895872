import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../_services';
import { MatDialogRef, MatDialog} from '@angular/material/dialog';
import { ResetPasswordModal } from './mensajes/resetPasswordModal/resetPasswordModal.component';
import { ForgotPassEmailService } from '../_services/forgot-pass-email.service';
import { ModalSuccess } from '../components/mensajes/modalSuccess/modalSuccess';
import { ModalError } from '../components/mensajes/modalError/modalError';


@Component({ 
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
 })


export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loadingPage: boolean = true;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        public dialog: MatDialog,
        public modalSuccess: MatDialog,
        public modalError: MatDialog,
        private forgotPassEmailService: ForgotPassEmailService
    ) { }

    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // VALIDA SI LA SESIÓN ESTA INICIADA SI SI LO MANDA AL HOME Y SI NO LO DEJA ENTRAR AL LOGIN
        if(!localStorage.getItem('currentUser')){
            this.authenticationService.logout();
        }else{
            this.router.navigate([this.returnUrl]);
        }
        this.loadingPage = false;
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    if(data.code == 200){this.router.navigate([this.returnUrl]);}
                    else{
                        this.dialog.open(ErrorInicio);
                        this.loading = false;
                    }
                }
            );
    }


    clickResetPassword(e:any){
        this.openDialog();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(ResetPasswordModal, {
          width: '60%',
          data: {},
          panelClass:'modal-reset-pass'
        });
    
        dialogRef.afterClosed().subscribe(this.handleCloseResetDialog.bind(this));
    }

    openModalSuccess(){
        const dialogRef = this.modalSuccess.open(ModalSuccess, {
            data: {message:"Hemos enviado un email a tu correo electrónico para que puedas cambiar tu contraseña"},
   
          });
      
          dialogRef.afterClosed().subscribe(()=> {});
    }

    openModalError(){
        const dialogRef = this.modalError.open(ModalError, {
            data: {
                   message:"No es posible realizar la accion en este momento.",
                   title:"Lo sentimos ocurrió un error"
                  }
   
          });
      
          dialogRef.afterClosed().subscribe(()=> {});
    }

    handleCloseResetDialog(data: string){

        if(data === undefined) return;

        const index = data.indexOf('|');
        const email = data.substr(0, index).trim();
        const action = data.substr(index + 1, data.length)

        if(action === "cancel" || email === "") return

        this.loadingPage = true;
        
        this.forgotPassEmailService.sendRecoveryEmail(email).subscribe(result => {
            this.loadingPage = false;
            this.openModalSuccess();
        }, 
        err =>{
            this.loadingPage = false;
            this.openModalError();
        }
        );
    }

}

@Component({
    selector: 'error-inicio',
    templateUrl: './mensajes/errorInicio.html',
    styleUrls: ['./mensajes/errorInicio.css']
  })
  export class ErrorInicio{
    constructor(
      public dialogRef: MatDialogRef<ErrorInicio>) {
        dialogRef.disableClose = true;
    }
    aceptar(){
      this.dialogRef.close();
    }
  }