import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(environment.enjoyServices.login, { "email" : username, "password" : password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user.info && user.info.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user.info));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    register(username: string, password: string, email: string){
        return this.http.post<any>(environment.enjoyServices.registro, { "email" : email, "password" : password, "firstname" : username})
        .pipe(map(res => {
            return res;
        }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    validarContrasena(json){
        return this.http.post<any>(environment.enjoyServices.validarContrasena, json);
    }
    cambiarContrasena(json){
        return this.http.post<any>(environment.enjoyServices.cambiarContrasena, json);
    }
    cargarDatosUsuario(json){
        return this.http.post<any>(environment.enjoyServices.cargaDatosUsuario, json)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user.info && user.info.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user.info));
                    this.currentUserSubject.next(user);
                }
                return user;
        }));
    }
}