import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/_services';
import { first } from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  loadingPage: boolean = true;
  loading: boolean = false;
  returnUrl: string;
  formularioValid: boolean = false;
  messages: any = [];
  aceptoTerminosCondiciones: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService : AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.loadingPage = false;
  }

  get f() { return this.registerForm.controls; }


  onSubmit() {

    this.loading = true;
    if (this.registerForm.invalid) {
        this.loading = false;
        return;
    }
    this.authenticationService.register(this.f.username.value, this.f.password.value, this.f.email.value)
    .pipe(first())
    .subscribe(
        data => {
          debugger;
          if(data.status == 200){
            this.loading = false;
            this.dialog.open(CreadaExito);
          }
          else{
            this.loading = false;
            this.dialog.open(CreadaFail);
          }
        }
    );
}

  validaCamposFormulario = () => {
    this.messages = [];
    console.log(this.f);
    if(!this.f.username.valid && this.f.username.value != ''){
      this.messages.push('El nombre es invalido');
    }
    if(!this.f.email.valid && this.f.email.value != ''){
      this.messages.push('El correo es invalido');
    }
    if(!this.f.password.valid && this.f.password.value != ''){
      this.messages.push('La contraseña es invalida');
    }
  }

  aceptoTerminos = () => {
    this.aceptoTerminosCondiciones = !this.aceptoTerminosCondiciones;
  }

}

@Component({
  selector: 'cuenta-creada-exito',
  templateUrl: './../mensajes/registroFail.html',
  styleUrls: ['./../mensajes/registroFail.css']
})
export class CreadaFail{
  constructor(
    public dialogRef: MatDialogRef<CreadaFail>) {
      dialogRef.disableClose = true;
  }
  aceptar(){
    this.dialogRef.close();
  }
}

@Component({
  selector: 'cuenta-creada-exito',
  templateUrl: './../mensajes/registroCompletado.html',
  styleUrls: ['./../mensajes/registroCompletado.css']
})
export class CreadaExito{
  constructor(
    public dialogRef: MatDialogRef<CreadaExito>) {
      dialogRef.disableClose = true;
  }
  aceptar(){
    this.dialogRef.close();
  }
}