import { Component, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {

  constructor(public dialog: MatDialog, private serviceAuth: AuthenticationService) { }

  nombreUsuario: string = '';
  nombreEmpresa: string = '';
  ubicacionEmpresa: string = '';
  loadingInput: any = false;

  ngOnInit() {
    this.nombreUsuario = JSON.parse(localStorage.getItem('currentUser')).firstname;
    this.nombreEmpresa = JSON.parse(localStorage.getItem('currentUser')).info.nombreEmpresa ? JSON.parse(localStorage.getItem('currentUser')).info.nombreEmpresa : '';
    this.ubicacionEmpresa = JSON.parse(localStorage.getItem('currentUser')).info.ubicacionEmpresa ? JSON.parse(localStorage.getItem('currentUser')).info.ubicacionEmpresa : '';
  }

  verificarInformacion(input){
    let json = {
      jwt: JSON.parse(localStorage.getItem('currentUser')).token,
      json: this.devolverValor(input)
    };
    this.serviceAuth.cargarDatosUsuario(json)
    .subscribe((data) => {
      if(data.code == 200){
        this.loadingInput = false;
      }else{
        this.loadingInput = false;
      }
    });
  }

  devolverValor(input){
    let json = {
      firstname: this.nombreUsuario,
      info: {
        nombreEmpresa: this.nombreEmpresa,
        ubicacionEmpresa: this.ubicacionEmpresa
      }
    };
    switch(input){
      case 'nombreU':
        this.loadingInput = 1;
        return json;
        break;

      case 'nombreEm':
        this.loadingInput = 2;
        return json;
        break;

      case 'ubicacionE':
        this.loadingInput = 3;
        return json;
        break;

      default:
        console.log('No existe Case');
        return 'No existe';
        break;
    }
  }

  cambiarContrasena(){
    const dialogRef = this.dialog.open(CambiarContrasena);

    dialogRef.afterClosed().subscribe(data => {
    });
  }

}


@Component({
  selector: 'cambiar-contrasena',
  templateUrl: './../mensajes/mensajeContrasena.html',
  styleUrls: ['./../mensajes/mensajeContrasena.css']
})
export class CambiarContrasena {

  
  contrasenaActual = "";
  nuevaContra = "";
  repContra = "";
  errorPass = false;
  successPass = false;
  
  constructor( private serviceAuth: AuthenticationService, private dialogRef: MatDialogRef<CambiarContrasena>) {
    
  
  
  }
  cambioContrasena(){
    this.errorPass = false;
  }
  cambiarContrasena(){
    let json = {
      jwt : JSON.parse(localStorage.getItem('currentUser')).token,
      password : this.nuevaContra
    }
    this.serviceAuth.cambiarContrasena(json)
    .subscribe((data) => {
      if(data.code == 200){
        this.dialogRef.close();
      }else if(data.code == 401){
        this.errorPass = true;
      }else if(data.code == 500){

      }
    });
  }
  verificarContrasena(){
    let json = {
      pass : this.contrasenaActual,
      jwt : JSON.parse(localStorage.getItem('currentUser')).token
    };
    this.serviceAuth.validarContrasena(json)
    .subscribe((data) => {
      if(data.code == 200){
        this.successPass = true;
      }else if(data.code == 401){
        this.errorPass = true;
      }else if(data.code == 500){

      }
    });
  }
  
}