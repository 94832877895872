import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TokenSucces {

    public result$: Observable<any> = null;

    constructor(private http: HttpClient) {
    }

    verificarAcessToken(token){
        return this.http.post(environment.enjoyServices.tokenUser, token);
    }
    verificarIntegraciones(token){
        return this.http.post(environment.enjoyServices.integracionesAsosciadas, token);
    }
}