import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Location} from '@angular-material-extensions/google-maps-autocomplete';
import { SincronizacionEnvios } from 'src/app/_services/sincronizar.service';
import { Router } from '@angular/router';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment'
import { UserService } from 'src/app/_services';


export interface PeriodicElement {
  cliente: any;
  numPedido: any;
  creacion: any;
  distancia: any;
}

let ELEMENT_DATA: any[] = [];

@Component({
  selector: 'app-envios',
  templateUrl: './envios.component.html',
  styleUrls: ['./envios.component.css']
})

export class EnviosComponent implements OnInit {

  constructor( private sincronizador : SincronizacionEnvios, private router: Router, public dialog: MatDialog, public userService: UserService) {
    this.userService.creditos$.subscribe(
      (creditos) => {
          this.creditos = creditos;
          if(this.creditos <= 0){
            this.dialog.open(CreditosNoDisponibles);
          }
      }
    );
  }

  ngOnInit() {
    this.sincronizador.informacionPedidos = {};
  }

  creditos: number = 0;

  sincronizando: boolean = false;
  SY : boolean = false;

  loadingPage: boolean = false;

  displayedColumns: string[] = ['select', 'numPedido', 'cliente', 'creacion', 'distancia', 'direccion'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  selection = new SelectionModel(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.numPedido + 1}`;
  }

  sincronizarPedidos(ecommerce){
    if(ecommerce=='SY'){
      this.sincronizando = true;
      let jwt = JSON.parse(localStorage.getItem('currentUser'));
      let Jentrada = {
        "jwt" : jwt.token
      };
      this.sincronizador.sincronizarEnviosShopify(Jentrada).subscribe((data:any) => {
        if(data.status == 200){
          console.log(JSON.stringify(data));
          let infoExtra = ELEMENT_DATA;
           _.remove(infoExtra, obj => {
            return obj.ecommerce == 'SY';
          });
          data.data.forEach((dato) => {
            dato.creacion = moment(dato.creacion).format('DD/MM/YYYY');
          });
          ELEMENT_DATA = data.data;
          if(infoExtra.length > 0){
            infoExtra.forEach(element => {
              ELEMENT_DATA.push(element);
            });
          }
          this.selection.selected;
          this.dataSource = new MatTableDataSource(ELEMENT_DATA);
          this.selection = new SelectionModel(true, []);
        }else{
          let infoExtra = ELEMENT_DATA;
           _.remove(infoExtra, obj => {
            return obj.ecommerce == 'SY';
          });
          ELEMENT_DATA = [];
          if(infoExtra.length > 0){
            infoExtra.forEach(element => {
              ELEMENT_DATA.push(element);
            });
          }
        }
        this.sincronizando = false;
      },
      (err => {
        this.sincronizando = false;
      }));
    }
  }

  async realizarEnvio(){
    this.sincronizador.informacionPedidos = this.selection.selected;
    let origenes = [];
    this.sincronizador.informacionPedidos.forEach((item) => {
      origenes.push(item.envio.puntoRecogida.direccion);
    });
    origenes = origenes.filter(function(value, index, self) { 
      return self.indexOf(value) === index;
    });
    if(origenes.length > 1){
      const dialogRef = this.dialog.open(SeleccionarOrigen, {
        data: {origenes: origenes}
      });
      dialogRef.afterClosed().subscribe(async (data) => {
        this.sincronizador.informacionPedidos.forEach((item) => {
          item.envio.puntoRecogida.direccion = data;
        });
        let json: any = await this.empaquetandoDatosEnvio();
        this.eligeFecha(json);
      });
    }else{
      let json: any = await this.empaquetandoDatosEnvio();
      this.eligeFecha(json);
    }
  }

  eligeFecha(json){
    const dialogRef = this.dialog.open(ElegirFechaPedido);

    dialogRef.afterClosed().subscribe(data => {
      this.loadingPage = true;
      if(data){
        data = moment(data).format('DD/MM/YYYY');
        json.envio.puntoRecogida.fecha = data;
        this.sincronizador.informacionPedidos = json;
        this.sincronizador.cotizadorDeEnvios(json).subscribe((res: any) => {
          if(res.code == 200){
            this.loadingPage = false;
            this.sincronizador.preciosInfo = res;
            this.router.navigate(['/envios/pago']);
          }else{
            this.loadingPage = false;
          }
        },(err) => {

        })
      }else{
        this.loadingPage = false;
      }
    });
  }

  empaquetandoDatosEnvio(){
    let json: any = {
      envio: {
        puntoRecogida: this.sincronizador.informacionPedidos[0].envio.puntoRecogida,
        tipoEnvio: "NORMAL",
        puntosEntrega: []
      },
      jwt: JSON.parse(localStorage.getItem('currentUser')).token
    };
    this.sincronizador.informacionPedidos.forEach((item) => {
      let info = item.envio.puntoEntrega;
      json.envio.puntosEntrega.push(info);
    });
    return json;
  }

  crearEnvio(){
    let ubicacionesOrigenes: any;
    ubicacionesOrigenes = ELEMENT_DATA;
    const dialogRef = this.dialog.open(CrearEnvio, {
      data: {origenes: ubicacionesOrigenes}
    });

    dialogRef.afterClosed().subscribe(data => {
      if(data){
        ELEMENT_DATA.push(data);
        this.selection.selected;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.selection = new SelectionModel(true, []);
      }
    });
  }

  eliminarEnvios(){
    console.log(this.selection.selected);
    this.selection.selected.forEach(item => {
      let index: number = ELEMENT_DATA.findIndex(d => d === item);
      console.log(ELEMENT_DATA.findIndex(d => d === item));
      ELEMENT_DATA.splice(index,1)
      this.dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);
      this.selection = new SelectionModel<Element>(true, []);
    });
  }

}

@Component({
  selector: 'crear-envio',
  templateUrl: './../mensajes/mensajeCrearEnvio.html',
  styleUrls: ['./../mensajes/mensajeCrearEnvio.css']
})
export class CrearEnvio implements OnInit {

  latMexico = 19.4326077;
  lngMexico = -99.13320799999997;
  latOrigin : number;
  lngOrigin : number;
  latDest : number;
  lngDest : number;
  zoom = 12;
  distanciaTotal : string;
  distancia_value: number;
  inicial: number = 1;

  correoEntrega : FormControl;
  tituloEnvio : FormControl;
  direccionRecogida : FormControl;
  direccionEntrega : FormControl;
  nombreEntrega : FormControl;
  nombreRecive : FormControl;
  telefonoEntrega : FormControl;
  contenido : FormControl;
  correoRecive : FormControl;
  codigoPostal : FormControl;
  telefonoRecive : FormControl;
  formEnvio: FormGroup;
  cantidad: FormControl;

  direccionEntregaModel: string = '';
  direccionRecogidaModel: string = '';
  titulo: string = '';
  
  constructor(
    public dialogRef: MatDialogRef<CrearEnvio>
    ,@Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
    }
    
  public origin: any;
  public destination: any;
  public travelMode: string = 'DRIVING'
  // CONTROL INPUTS

  ngOnInit(){
    this.correoEntrega = new FormControl('', [Validators.required, Validators.email]);
    this.tituloEnvio = new FormControl('', [Validators.required]);
    this.direccionRecogida = new FormControl('', [Validators.required]);
    this.direccionEntrega = new FormControl('', [Validators.required]);
    this.nombreEntrega = new FormControl('', [Validators.required]);
    this.nombreRecive = new FormControl('', [Validators.required]);
    this.telefonoEntrega = new FormControl('', [Validators.required]);
    this.contenido = new FormControl('', [Validators.required]);
    this.correoRecive = new FormControl('', [Validators.required , Validators.email]);
    this.codigoPostal = new FormControl('', [Validators.required]);
    this.telefonoRecive = new FormControl('', [Validators.required]);
    this.cantidad = new FormControl('', [Validators.required]);

    this.formEnvio  = new FormGroup({
      correoEntrega : this.correoEntrega,
      tituloEnvio : this.tituloEnvio,
      direccionRecogida : this.direccionRecogida,
      direccionEntrega : this.direccionEntrega,
      nombreEntrega : this.nombreEntrega,
      nombreRecive : this.nombreRecive,
      telefonoEntrega : this.telefonoEntrega,
      contenido : this.contenido,
      correoRecive : this.correoRecive,
      codigoPostal : this.codigoPostal,
      telefonoRecive : this.telefonoRecive,
      cantidad: this.cantidad
    });
  }


  getErrorCorreo1() {
    if (this.formEnvio.get('correoEntrega').hasError('required')) {
      return 'El campo es requerido';
    }

    return this.formEnvio.get('correoEntrega').hasError('email') ? 'Correo no valido' : '';
  }
  getErrorCorreo2() {
    if (this.formEnvio.get('correoRecive').hasError('required')) {
      return 'El campo es requerido';
    }

    return this.formEnvio.get('correoRecive').hasError('email') ? 'Correo no valido' : '';
  }

  getErrorInputText(){
    if (this.formEnvio.get('tituloEnvio').hasError('required')) {
      return 'El campo es requerido';
    }else if(this.formEnvio.get('tituloEnvio').hasError('minLength')){
      return 'El campo requiere de un texto más grande';
    }
  }

  aceptarMensaje(): void {
    const dt = new Date();
    let day = `${
      (dt.getMonth()+1).toString().padStart(2, '0')}/${
      dt.getDate().toString().padStart(2, '0')}/${
      dt.getFullYear().toString().padStart(4, '0')} ${
      dt.getHours().toString().padStart(2, '0')}:${
      dt.getMinutes().toString().padStart(2, '0')}:${
      dt.getSeconds().toString().padStart(2, '0')}`;
    let json = {
      ecommerce : 'CR',
      envio: {
        tipoEnvio: this.formEnvio.get('tituloEnvio').value,
        puntoRecogida: {
          direccion: this.formEnvio.get('direccionRecogida').value,
          // interior:
          // contenido:
          nombre: this.formEnvio.get('nombreEntrega').value,
          correo: this.formEnvio.get('correoEntrega').value,
          telefono: this.formEnvio.get('telefonoEntrega').value,
        },
        puntoEntrega:{
          direccion: this.formEnvio.get('direccionEntrega').value,
          // interior: 
          nombre: this.formEnvio.get('nombreRecive').value,
          correo: this.formEnvio.get('correoRecive').value,
          telefono: this.formEnvio.get('telefonoRecive').value,
          codigoPostal: this.formEnvio.get('codigoPostal').value
          // obj: 
        }
      },
      creacion: day,
      distanciaValue: this.distancia_value,
      distanciaTexto : this.distanciaTotal,
      existenRutas: true
    }
    this.dialogRef.close(json);
  }
  cancelarEnvio(): void {
    this.dialogRef.close();
  }
  onAutocompleteSelectedOrigin(e){
    this.formEnvio.get('direccionRecogida').setValue(e.formatted_address);
  }

  onLocationSelectedOrigin(location: Location){
    this.zoom = 18;
  }
  onAutocompleteSelectedDest(e){
    this.formEnvio.get('direccionEntrega').setValue(e.formatted_address);
  }
  onLocationSelectedDest(location: Location){
    this.zoom = 18;
  }
  cambioMapa(data){
    this.distanciaTotal = data.routes[0].legs[0].distance.text;
    this.distancia_value = data.routes[0].legs[0].distance.value;
  }
  agregaInformacion(input){
    this.inicial = input;
  }
}
@Component({
  selector: 'seleccionar-origen',
  templateUrl: './../mensajes/mensajeSeleccionOrigen.html',
  styleUrls: ['./../mensajes/mensajeSeleccionOrigen.css']
})
export class SeleccionarOrigen{
  origenes: any;
  selectedValue: any;
  constructor(
    public dialogRef: MatDialogRef<CrearEnvio>
    ,@Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
      this.origenes = data.origenes;
  }
  aceptar(){
    this.dialogRef.close(this.selectedValue);
  }
}
@Component({
  selector: 'seleccionar-origen',
  templateUrl: './../mensajes/mensajeFechaPedido.html',
  styleUrls: ['./../mensajes/mensajeFechaPedido.css']
})
export class ElegirFechaPedido{
  fechaPedido: any;
  minDate: any = new Date();
  constructor(
    public dialogRef: MatDialogRef<ElegirFechaPedido>) {
      dialogRef.disableClose = true;
  }
  aceptar(){
    this.dialogRef.close(this.fechaPedido);
  }
  cancelar(){
    this.dialogRef.close(false);
  }
}

@Component({
  selector: 'creditos-no-disponibles',
  templateUrl: './../mensajes/mensajeCreditos.html',
  styleUrls: ['./../mensajes/mensajeCreditos.css']
})

export class CreditosNoDisponibles{
  constructor(public dialogRef: MatDialogRef<CreditosNoDisponibles>){
  }

  cerrar(){
    this.dialogRef.close();
  }
}