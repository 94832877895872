import { Component, Input, AfterViewInit, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/_services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-creditos',
  templateUrl: './creditos.component.html',
  styleUrls: ['./creditos.component.css']
})
export class CreditosComponent implements AfterViewInit, OnDestroy  {
  value = 0;

  @Input() creditos: number;
  
  @ViewChild('cardInfo') cardInfo: ElementRef;
  
  stripe = Stripe(environment.enjoyKeys.stripeAPI);
  elements = this.stripe.elements();
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  dimanicPrice: number = 2000;
  errorPayment: string = '';
  price: number = 0;
  priceError: boolean = false;
  messagePrice: string = '';
  loadingPage: boolean = false;
  
  constructor(private userService : UserService, private cd: ChangeDetectorRef, public dialog: MatDialog) { }

  ngAfterViewInit() {
    this.resizable(document.getElementById('number'),7,document.getElementById('inputPadreNumber'));
    this.card = this.elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.errorPayment = '';
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    let response: any = await this.stripe.createToken(this.card);

    if (response.error) {
      console.log('Something is wrong:', response.error);
    } else {
      console.log('Success!', response.token);
      let json = response.token;
      json.amount = (this.price*100);
      let jwt = JSON.parse(localStorage.getItem('currentUser'));
      json.jwt = jwt.token;
      json = JSON.stringify(json);
      this.loadingPage = true;
      this.userService.pagoStripe(json)
      .subscribe((data) => {
        if(data.code == 402){
          this.errorPayment = 'Pago no autorizado'
          this.loadingPage = false;
        }else{
          this.loadingPage = false;
          this.openDialog(
            'El pago fue realizado con exito',
            `Tu carga de $${this.price} MXN fue agregada correctamente.`
          );
        }
      },error => {
        this.loadingPage = false;
        this.errorPayment = 'Pago no autorizado'
      }
      );
    }
  }
  comprobarCantidad() {
    if(this.price<100){
      this.priceError = true;
      this.messagePrice = 'El minimo de creditos que tienes que agregar debe ser de $100.00 MXN'
    }else if(this.price>50000){
      this.priceError = true;
      this.messagePrice = 'El monto maximo de creditos que puedes agregar es de $50,000.00 MXN'
    }else{
      this.priceError = false;
      this.messagePrice = ''
    }
  }
  resizable (el, factor,creciente) {
    let int = Number(factor) || 7.7;
    function resize () {
      creciente.style.width = ((el.value.length+1) * int) + '%';
    }
    let e = 'keyup,keypress,focus,blur,change'.split(',');
    for (var i in e) el.addEventListener(e[i],resize,false);
    resize();
  }

  openDialog(title, message): void {
    const dialogRef = this.dialog.open(MuestraMensaje, {
      data: {title, message}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.userService.agregarCreditos(this.price);
      this.price = 0;
      this.priceError = false;
    });
  }

}

export interface DialogData {
  title: string,
  message: string
}

@Component({
  selector: 'mensaje-informativo',
  templateUrl: 'mensaje.html',
  styleUrls: ['mensaje.css']
})
export class MuestraMensaje {

  message: string;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<MuestraMensaje>, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.title = data.title;
      this.message = data.message;
  }

  aceptarMensaje(): void {
    this.dialogRef.close();
  }

}