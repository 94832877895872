import { Component } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '../_models';
import { UserService, AuthenticationService } from '../_services';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    users: User[] = [];
    Noshow: boolean = false;
    creditos: number = 0;

    
    

    constructor(private userService: UserService, private authenticationService: AuthenticationService, private router: Router,
        private dateAdapter: DateAdapter<Date>) {
            this.dateAdapter.setLocale('es');
        }

    ngOnInit() {
        this.userService.getAll().pipe(first()).subscribe(users => {
            this.users = users;
        });
        this.userService.creditos$.subscribe(
            (creditos) => {
                this.creditos = creditos;
            }
        );
    }

    events: string[] = [];
    opened: boolean = true;

    shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
    

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}