import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SincronizacionEnvios {

    informacionPedidos: any;
    preciosInfo: any;

    constructor(private http: HttpClient) {
    }

    sincronizarEnviosShopify(token) {
        return this.http.post(environment.enjoyServices.sincronizarEnvios, token);
    }
    
    cotizadorDeEnvios(envios){
        return this.http.post(environment.enjoyServices.costoPorEnvio, envios);
    }

    realizarPagoPorEnvio(req){
        return this.http.post(environment.enjoyServices.pagoPorEnvio, req);
    }
    obtenerPedidos(req){
        return this.http.post(environment.enjoyServices.pedidosUsuario, req);
    }
    obtenerDireccionCorta(req){
        let json = {
            url: environment.enjoyServices.googleMapsDirectionsOptimaze+req
        };
        return this.http.post(environment.enjoyServices.direcciones, json);
    }
}