import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
    private valorCreditos = 0.1;
    private totalCreditos: any = 0;
    private creditos: BehaviorSubject <any> = new BehaviorSubject <number>(this.valorCreditos);
    
    creditos$: Observable<number> = this.creditos.asObservable();

    jwt: string = JSON.parse(localStorage.getItem('currentUser')).token
    constructor(private http: HttpClient) {
        this.getCreditos();
    }

    getCreditos(){
        return this.http.post<any>(environment.enjoyServices.creditosDisponible, { "jwt" : this.jwt })
            .subscribe((data: any) => {
                if(data.status == 200){
                    this.totalCreditos = data.creditos;
                    this.creditos.next(data.creditos);
                }
            });
    }

    agregarCreditos(addCreditos){
        this.totalCreditos = parseFloat(this.totalCreditos) + parseFloat(addCreditos);
        this.valorCreditos = this.totalCreditos.toFixed(2);
        this.creditos.next(this.valorCreditos);
    }

    pagoStripe(token): Observable<any>{
        return this.http.post(environment.enjoyServices.cargoStripe, token);
    }

    getAll() {
        return this.http.get<User[]>(`/`);
    }
}