import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TokenSucces } from 'src/app/_services/tokenSucces';
import {Router} from "@angular/router"

@Component({
  selector: 'app-iexitosa',
  templateUrl: './iexitosa.component.html',
  styleUrls: ['./iexitosa.component.css']
})
export class IexitosaComponent implements OnInit {

  private routeSub: Subscription;
  constructor(private route: ActivatedRoute, private tokeService: TokenSucces, private router: Router) {}

  tokenAccess: string;

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.tokenAccess = params.id;
      let jwt = JSON.parse(localStorage.getItem('currentUser'));
      let Jentrada = {
        "jwt" : jwt.token,
        "token" : this.tokenAccess
      };
      this.tokeService.verificarAcessToken(Jentrada)
      .subscribe((data: any) => {
        if(data.code!=200){
          this.router.navigate(['/'])
        }
      });
    });
  }

}
