import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';
import { AuthGuard } from './_guards';
import { RegisterComponent } from './register/register/register.component';
import { EnviosComponent } from './components/envios/envios.component';
import { IntegracionesComponent } from './components/integraciones/integraciones.component';
import { MisEnviosComponent } from './components/mis-envios/mis-envios.component';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { CreditosComponent } from './components/creditos/creditos.component';
import { ConectarapiComponent } from './components/conectarapi/conectarapi.component';
import { IexitosaComponent } from './components/iexitosa/iexitosa.component';
import { PagoComponent } from './components/pago/pago.component';
import { ExpressComponent } from './components/express/express.component';


const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: '/express', pathMatch: 'full' },
            { path: 'envios', component: EnviosComponent },
            { path: 'integraciones', component: IntegracionesComponent },
            { path: 'integraciones/conectar/:id', component: ConectarapiComponent },
            { path: 'integracionShopifyExitosa/:id', component: IexitosaComponent },
            { path: 'misenvios', component: MisEnviosComponent },
            { path: 'misenvios/:success', component: MisEnviosComponent },
            { path: 'configuracion', component: ConfiguracionComponent },
            { path: 'creditos', component: CreditosComponent },
            { path: 'envios/pago', component: PagoComponent},
            { path: 'express', component: ExpressComponent}
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'resetPassword',
        component: ResetPasswordComponent
    },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);