import { Component, OnInit } from '@angular/core';
import { SincronizacionEnvios } from 'src/app/_services/sincronizar.service';
import { UserService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css']
})
export class PagoComponent implements OnInit {

  constructor(private sincronizador: SincronizacionEnvios, private userService: UserService, private router: Router) {

  }
  envios: any;
  creditos: number = 0;
  totalPagar: number = 0;
  informacionPedidos: any;
  loadingPage: boolean = false;

  ngOnInit() {
    this.userService.creditos$.subscribe(
      (creditos) => {
          this.creditos = creditos;
      }
    );
    this.informacionPedidos = this.sincronizador.informacionPedidos;
    this.totalPagar = this.sincronizador.preciosInfo.precio;
  }

  pagarEnvio(){
    this.loadingPage = true;
    this.sincronizador.realizarPagoPorEnvio(this.informacionPedidos)
    .subscribe((data: any) => {
      if(data.code == 200){
        this.loadingPage = false;
        this.router.navigate(['/misenvios/exito']);
      }
    });
  }

}
