import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ForgotPassEmailService } from '../_services/forgot-pass-email.service';
import { MatDialogRef, MatDialog} from '@angular/material/dialog';
import { ModalSuccess } from '../components/mensajes/modalSuccess/modalSuccess';
import { ModalError } from '../components/mensajes/modalError/modalError';


@Component({ 
    templateUrl: './resetPassword.component.html',
    styleUrls: ['./resetPassword.component.css']
 })


export class ResetPasswordComponent implements OnInit {


    registerForm: FormGroup;
    loadingPage: boolean = true;
    loading: boolean = false;
    validForm: boolean = false;
    messages: any = [];
    private token: string = null;


    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router,
        public modalSuccess: MatDialog,
        public modalError: MatDialog,
        private forgotPassEmailService: ForgotPassEmailService
    ) { }

    ngOnInit() {
         this.token = this.route.snapshot.queryParams['token'];

        if( this.token === undefined || this.token === null ) {
            this.router.navigate(['login']);
        }

        this.forgotPassEmailService.validateToken(this.token)
        .then(res => res.json())
        .catch(error => {
            console.error('Error:', error)
            this.loadingPage = false;
        })
        .then(response =>{ 
            this.loadingPage = false;
            if(!response.success){
                //redirect
                this.router.navigate(['login']);
            }
        });

        this.registerForm = this.formBuilder.group({
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
          });
    }

    openModalSuccess(){
        const dialogRef = this.modalSuccess.open(ModalSuccess, {
            data: {message:"¡Tu contraseña se ha actualizado con éxito!"},
   
          });
      
          dialogRef.afterClosed().subscribe(()=> {
            this.router.navigate(['login']);
            });
    }



    validateForm= () => {
        this.messages = [];
        const password = this.registerForm.controls.password.value;
        const confirmPassword = this.registerForm.controls.confirmPassword.value;

        if(!this.isValidPassword(password)){
            this.messages.push('La nueva contraseña debe contener al menos 2 numeros y 2 letras');
        }else if(password.length < 8){
            this.messages.push('La nueva contraseña debe tener minimo 8 caracteres');
        }else if(password != confirmPassword){
            this.messages.push('La nueva contraseña no coincide con la de confirmacion');
        }else{
            this.validForm = true;
        }
     
      }

      isValidPassword(pass){
         const regex = new RegExp(/((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]$/i);  
         return regex.test(pass);   
      }

      onSubmit(f: NgForm) {
          const newPassword = this.registerForm.value.password;
          this.loadingPage = true;
          this.forgotPassEmailService.resetPassword(newPassword, this.token).subscribe(
              result =>{
                 this.loadingPage = false;
                 this.openModalSuccess();
               },
               err =>{
                this.loadingPage = false;
                this.openModalError();
            }
          );
    }


    openModalError(){
        const dialogRef = this.modalError.open(ModalError, {
            data: {
                   message:"No es posible realizar la accion en este momento.",
                   title:"Lo sentimos ocurrió un error"
                  },
   
          });
      
          dialogRef.afterClosed().subscribe(()=> {});
    }
}

