import { Component, OnInit } from '@angular/core';
import { TokenSucces } from 'src/app/_services/tokenSucces';

@Component({
  selector: 'app-integraciones',
  templateUrl: './integraciones.component.html',
  styleUrls: ['./integraciones.component.css']
})
export class IntegracionesComponent implements OnInit {

  loadingPage: boolean = true;
  SY: boolean;

  constructor(private tokenSuccess: TokenSucces) { }

  ngOnInit() {
    let jwt = JSON.parse(localStorage.getItem('currentUser'));
    let Jentrada = {
      "jwt" : jwt.token,
    };
    this.tokenSuccess.verificarIntegraciones(Jentrada)
    .subscribe((data: any) => {
      if(data.code == 200){
        this.SY = data.SY;
        this.loadingPage = false;
      }
    }); 
  }

}
