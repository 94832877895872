export const environment = {
    production: false,
    enjoyServices: {
        login: 'https://enjoyenvios.mx/enjoy/rest-api-authentication/api/login.php',
        registro: 'https://enjoyenvios.mx/enjoy/rest-mails/api/registrarUsuario.php',
        validarContrasena: 'https://enjoyenvios.mx/enjoy/rest-api-authentication/api/validarContrasena.php',
        cambiarContrasena: 'https://enjoyenvios.mx/enjoy/rest-api-authentication/api/cambiarContrasena.php',
        cargaDatosUsuario: 'https://enjoyenvios.mx/enjoy/rest-api-authentication/api/cargaDatos.php',
        sincronizarEnvios: 'https://enjoyenvios.mx/enjoy/rest-api-integrations/api/sincronizarEnvios.php',
        costoPorEnvio: 'https://enjoyenvios.mx/enjoy/rest-api-payments/api/costoPorEnvio.php',
        pagoPorEnvio: 'https://enjoyenvios.mx/enjoy/rest-api-payments/api/pagoEnvio.php',
        pedidosUsuario: 'https://enjoyenvios.mx/enjoy/rest-api-payments/api/registros/pedidosUsuario.php',
        tokenUser: 'https://enjoyenvios.mx/enjoy/rest-api-integrations/api/integrateTokenUser.php',
        integracionesAsosciadas: 'https://enjoyenvios.mx/enjoy/rest-api-integrations/api/integracionesAsociadas.php',
        creditosDisponible: 'https://enjoyenvios.mx/enjoy/rest-api-authentication/api/creditos_disponibles.php',
        cargoStripe: 'https://enjoyenvios.mx/enjoy/rest-api-payments/api/cargoStripe.php',
        shopifyLink: 'https://enjoyenvios.mx/enjoy/rest-api-integrations/api/install.php?shop=',
        googleMapsDirectionsOptimaze: 'https://maps.googleapis.com/maps/api/directions/json?key=AIzaSyAGQqQpQMW7LNnNycDZmEP-pFKPaGRxfR4&origin=',
        direcciones: 'https://enjoyenvios.mx/enjoy/rest-api-payments/api/direcciones.php'
    },
    enjoyKeys: {
        stripeAPI: 'pk_test_biIR1J0Y9fkICEmUAmZGbag2',
        googleMapsAPI: 'AIzaSyAyMAQ-nsnJNZtTzRSukVQvcMWBa7CHrs4',
    }
};